
import { css } from "@emotion/react"

export const Footer: React.FC = () => {
    return (
        <div css={containerStyle}>
            <span css={copyrightStyle}>©️2023 torimawari</span>
        </div>
    )
}

const containerStyle = css({
    width: '100vw',
    padding: '20px 0',
    textAlign: 'center',
})

const copyrightStyle = css({
    margin: 'auto',
})