import React from "react";

import { css } from "@emotion/react"
import { useRecoilValue } from "recoil";
import { postModalState } from "../../store/postModalState";
import { Post } from "../../types/post";
import { DetailCard } from "../molecules/DetailCard";

type Props = {
  content: Post
}

export const PostTemplate: React.FC<Props> = (props) => {
  const { content } = props
  const showModal = useRecoilValue(postModalState)

  const containerStyle = css({
    width: '100vw',
    display: "flex",
    justifyContent: 'center',
    position: showModal ? 'fixed' : 'static',
  })

  if (!content) return null

  return (
    <div css={containerStyle}>
      <DetailCard content={content} />
    </div>
  );
}