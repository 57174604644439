const color = {
    // primary: '#009688',
    primary: '#E36255',
    primaryLight: '#EC9A86',
    secondaryBlue: '#A2C5C9',
    secondaryYellow: '#F3C262',
    secondaryBeige: '#F1E0CE',
    black: '#000000',
    darkGray: '#767676',
    lightGray: '#e8e8e8',
    white: '#ffffff',
    whiteTransparent: 'rgb(255, 255, 255, 0.6)',
    blackTransparent: 'rgb(0, 0, 0, 0.6)',
    transparent: 'transparent',
    blue: '#8bd3dd',
    heavyBlue: '#001858',
    yellow: '#ffd803',
    lightYellow: '#fef6e4',
    pink: '#f582ae',
    beige: '#f3d2c1'
}

export const FONT_COLOR = {
    PRIMARY: color.heavyBlue,
    SECONDARY: color.darkGray,
    ACCENT: color.pink,
    REVERSED: color.white
}

export const BACKGROUND_COLOR = {
    PRIMARY: color.lightYellow,
    SECONDARY: color.lightGray,
    ACCENT: color.heavyBlue,
    ACCENT_LIGHT: color.primaryLight,
    FOGGY: color.whiteTransparent,
    TRANSPARENT: color.transparent,
    OVERLAY: color.blackTransparent,
    ATTENTION: color.primary
}

export const BUTTON_COLOR = {
    BACKGROUND: {
        PRIMARY: color.blue,
    },
    FONT: {
        PRIMARY: color.heavyBlue,
    }
}

export const CARD_COLOR = {
    BACKGROUND: {
        PRIMARY: color.lightYellow,
        HOVER: color.beige
    },
    FONT: {
        PRIMARY: color.heavyBlue,
        HOVER: color.heavyBlue
    },
    BORDER: {
        PRIMARY: color.heavyBlue,
        HOVER: color.heavyBlue
    }
}

export const BORDER_COLOR = {
    PRIMARY: color.lightGray
}