import axios from 'axios'
import { convertObjectCamelToSnake, convertObjectSnakeToCamel } from '../utils/text';

type Props = {
  content?: any // TODO: 直す
}

const putContent = (props: Props) => {
  const {content} = props
  return axios.put(
    `${process.env.REACT_APP_API_ENDPOINT}/torimawari`,
    convertObjectCamelToSnake(content),
  ).then(res => {
    return convertObjectSnakeToCamel(res.data)
  })
}

export const usePutContent = () => {
  return {putContent: (props: Props) => putContent(props)}
}