import React, { useCallback, useEffect, useState } from "react";

import { css } from "@emotion/react"
// import ToriLogo from '../../assets/tori_logo.png'
import GoogleLoginButtonNormal from '../../assets/btn_google_signin_light_normal_web@2x.png'
import GoogleLoginButtonHover from '../../assets/btn_google_signin_light_focus_web@2x.png'
import GoogleLoginButtonClicked from '../../assets/btn_google_signin_light_pressed_web@2x.png'
import { BACKGROUND_COLOR, FONT_COLOR } from "../../resources/color";
import useDeviceType from "../../hooks/useDeviceType";

type Props = {
  onClickLogin: () => void
}

export const LoginTemplate: React.FC<Props> = ({onClickLogin}) => {
  const [loginButtonImage, setLoginButtonImage] = useState(GoogleLoginButtonNormal)
  const deviceType = useDeviceType()

  useEffect(() => {
    document.title = 'ログイン - うちのとりまわりじまん';
  });

  const changeLoginButton = useCallback((stat: 'normal' | 'hover' | 'clicked') => {
    switch (stat) {
      case 'hover':
        setLoginButtonImage(GoogleLoginButtonHover)
        break
      case 'clicked':
        setLoginButtonImage(GoogleLoginButtonClicked)
        break
      default:
        setLoginButtonImage(GoogleLoginButtonNormal)
    }
  }, [])

  const onClickLoginButton = useCallback(
    () => {
      changeLoginButton('clicked')
      onClickLogin()
    },
    [changeLoginButton, onClickLogin],
  )
  
  const loginContainerStyle = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: deviceType === 'mobile' ? 20 : 0,
    height: '100vh',
    justifyContent: 'center',
    gap: 30
  })

  return (
    <div css={containerStyle}>
      <div css={loginContainerStyle}>
        {/* <div css={logoStyle}>
          <img css={imageStyle} src={ToriLogo} alt="logo" />
          <p css={titleStyle}>うちのとりまわりじまん</p>
        </div> */}
        <div css={buttonContainerStyle}>
          <span css={textStyle}><a href='https://note.com/torimawari/n/nc68c76b0856d' css={linkStyle} target="_blank" rel="noreferrer">利用規約</a>と<a href='https://note.com/torimawari/n/ncbd5ec4fcd4a' css={linkStyle} target="_blank" rel="noreferrer">プライバシーポリシー</a>をご確認の上、ご利用ください。</span>
          <span css={textStyle}>「Sign in with Google」をクリックしGoogleアカウント連携をすると、同意とみなし本サイトにログインされます。</span>
          <button 
            css={loginButtonStyle} 
            onClick={onClickLoginButton} 
            onMouseOver={() => changeLoginButton('hover')} 
            onMouseLeave={() => changeLoginButton('normal')}
          >
            <img css={loginButtonImageStyle} src={loginButtonImage} alt="google_login_button" />
          </button>
        </div>
      </div>
    </div>
  );
}

const containerStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})
const buttonContainerStyle = css({
  display: 'flex',
  flexDirection: "column",
  alignItems: 'center',
  width: '100%',
  gap: 13
})
const loginButtonStyle = css({
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  width: 250,
  backgroundColor: BACKGROUND_COLOR.TRANSPARENT
})
const loginButtonImageStyle = css({
  width: '100%'
})
// const logoStyle = css({
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: 'center',
//   alignItems: 'center',
// })
// const titleStyle = css({
//   fontWeight: 800,
//   fontSize: 16
// })
const textStyle = css({
  fontSize: 14,
})
const linkStyle = css({
  color: FONT_COLOR.ACCENT,
  fontWeight: 'bold'
})
// const imageStyle = css({
//   width: 100,
// })