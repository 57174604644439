
import { css } from "@emotion/react";
import React from "react";
import { FONT_COLOR } from "../../resources/color";

export const NotFound: React.FC = () => {
  return (
    <div css={containerStyle}>
      ページが見つかりません。
    </div>
  );
}

const containerStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
  color: FONT_COLOR.SECONDARY,
  fontSize: 20,
  fontWeight: 'bold'
})