import { RequestContent } from './../components/organisms/PostModal';
import axios from 'axios'
import { convertObjectCamelToSnake, convertObjectSnakeToCamel } from '../utils/text';

type Props = {
  content?: RequestContent
}

const postContent = (props: Props) => {
  const {content} = props
  // const headers = {
  //   'x-api-key': process.env.REACT_APP_API_KEY,
  // }
  return axios.post(
    `${process.env.REACT_APP_API_ENDPOINT}/torimawari`,
    convertObjectCamelToSnake(content),
    // { headers }
  ).then(res => {
    return convertObjectSnakeToCamel(res.data)
  })
}

export const usePostContent = () => {
  return {postContent: (props: Props) => postContent(props)}
}