
import { css } from "@emotion/react";
import React from "react";
import { FONT_COLOR } from "../../resources/color";
// import { LeftBalloon } from "../atoms/LeftBalloon";
import { TagList } from "./TagList";
import { UserName } from "./UserName";
import useDeviceType from "../../hooks/useDeviceType";
import { getFormattedDate } from "../../utils/date";
import { TAGS } from "../../resources/tags";
import { Post } from "../../types/post";

type Props = {
  content: Post
}

export const DetailCard: React.FC<Props> = (props) => {
  const {content} = props
  const deviceType = useDeviceType()
  const isMobile = deviceType === 'mobile'

  const contentStyle = css({
    display: "flex",
    flexDirection: "column",
    gap: 26,
    borderRadius: 10,
    border: '1px solid black',
    margin: isMobile ? '120px 20px 20px' : '120px 30px 20px',
    width: 770,
    maxWidth: 770,
    padding: 50,
  },
  isMobile && {
    padding: 30
  })

  return (
    <div css={contentStyle}>
      <div css={titleStyle}>{content.title}</div>
      <TagList items={TAGS.filter((t) => content.tags?.includes(t.id))} />
      <div css={infoContainerStyle}>
        <UserName icon="🐤" name={content.userName} />
        <span css={dateStyle}>
          {getFormattedDate(content.createdAt)}
        </span>
        {/* <img css={shareButtonStyle} src={Twitter} alt="twitter" /> */}
      </div>
      <span css={cardImageContainerStyle}>
        <img css={cardImageStyle} src={content.imageUrl} alt={content.title} />
      </span>
      <div css={descriptionContainerStyle}>
        {content.description}
      </div>
    </div>
  )
}
const titleStyle = css({
  fontWeight: 800,
  fontSize: 22,
  lineHeight: 1,
  color: FONT_COLOR.PRIMARY
})
const cardImageContainerStyle = css({
  width: '100%',
  maxHeight: 600,
  textAlign: 'center'
})
const cardImageStyle = css({
  width: 'auto',
  height: 'auto',
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: "contain",
  borderRadius: 10,
  overflow: 'hidden'
})
const infoContainerStyle = css({
  height: 'auto',
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: 12,
  alignItems: "center"
})
const dateStyle = css({
  color: FONT_COLOR.SECONDARY,
  fontWeight: 'bold'
})
// const shareButtonStyle = css({
//   width: 30,
// })
const descriptionContainerStyle = css({
  padding: 10,
  whiteSpace: 'pre-wrap',
})