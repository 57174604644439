
import { css } from "@emotion/react";
import React from "react";
import { BACKGROUND_COLOR, FONT_COLOR } from "../../resources/color";

type Props = {
  item: any
  defaultColor?: string
  fontSize?: number
  selectedColor?: string
  selected?: boolean
  onChangeSelected?: (item: any) => void
}

export const TagItem: React.FC<Props> = ({ item, defaultColor = FONT_COLOR.PRIMARY, fontSize = 14, selectedColor = BACKGROUND_COLOR.ACCENT, selected = false, onChangeSelected }) => {
  const tagItemStyle = css({
    height: 14,
    borderRadius: 20,
    border: `1px solid ${item.selected || selected ? selectedColor : defaultColor}`,
    fontSize: fontSize,
    fontWeight: 800,
    color: item.selected || selected ? FONT_COLOR.REVERSED : FONT_COLOR.PRIMARY,
    padding: '6px 12px',
    display: "flex",
    alignItems: "center",
    cursor: onChangeSelected ? 'pointer' : 'default',
    background: item.selected || selected ? BACKGROUND_COLOR.ACCENT : BACKGROUND_COLOR.TRANSPARENT
  })

  return (
    <span css={tagItemStyle} onClick={() => onChangeSelected && onChangeSelected({...item, selected: !item.selected})}>{item.text}</span>
  )
}