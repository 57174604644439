import { FC, ReactNode } from "react";
import { createPortal } from "react-dom";

type Props = {
  children: ReactNode;
};

export const ModalPortal: FC<Props> = (props) => {
  return createPortal(
    props.children,
    document.getElementById('modal-portal') as Element
  )
}