import { convertObjectCamelToSnake } from './../utils/text';
import axios from 'axios'
import { convertObjectSnakeToCamel } from '../utils/text';

type Props = {
  id: string
  userId: string
}

const deleteContent = (props: Props) => {
  // const headers = {
  //   'x-api-key': process.env.REACT_APP_API_KEY,
  // }
  const payload = {
    data: convertObjectCamelToSnake(props),
    // headers
  }
  return axios.delete(
    `${process.env.REACT_APP_API_ENDPOINT}/torimawari`,
    payload
  ).then(res => {
    return convertObjectSnakeToCamel(res.data)
  })
}

export const useDeleteContent = () => {
  return {deleteContent: (props: Props) => deleteContent(props)}
}