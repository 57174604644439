import axios from 'axios'
import { convertObjectCamelToSnake, convertObjectSnakeToCamel } from '../utils/text';

type Props = {
  userId: string
}

const deleteUser = (props: Props) => {
  const payload = {
    data: convertObjectCamelToSnake(props),
  }
  return axios.delete(
    `${process.env.REACT_APP_API_ENDPOINT}/user`,
    payload
  ).then(res => {
    return convertObjectSnakeToCamel(res.data)
  })
}

export const useDeleteUser = () => {
  return {deleteUser: (props: Props) => deleteUser(props)}
}