import { css } from "@emotion/react";
import { FC } from "react";
import { BACKGROUND_COLOR, FONT_COLOR } from "../../resources/color";

type Props = {
  text: string
  onClick: () => void
  disabled?: boolean
  size?: 'large' | 'medium' | 'small'
  backgroundColor?: string
  textColor?: string
}

export const ActionButton: FC<Props> = ({text, onClick, disabled=false, size='medium', backgroundColor=BACKGROUND_COLOR.ACCENT, textColor=FONT_COLOR.REVERSED}) => {
  const buttonStyle = css({
    width: '100%',
    backgroundColor: backgroundColor,
    border: 'none',
    height: size === 'large' ? 46 : 40,
    borderRadius: 5,
    color: textColor,
    fontSize: size === 'large' ? 16 : 14,
    fontWeight: 800,
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: disabled ? '' : 'pointer'
  },
  `
    &:disabled {
      background-color: ${BACKGROUND_COLOR.SECONDARY}
    }
  `
  )

  return (
    <button onClick={onClick} css={buttonStyle} disabled={disabled}>{text}</button>
  )
}