
import { css } from "@emotion/react";
import React from "react";
import { BACKGROUND_COLOR, FONT_COLOR } from "../../resources/color";
import { ModalPortal } from "../portal/ModalPortal";

type Props = {
  showModal: boolean
  id?: string
  title: string
  text: string
  onClickCancel: () => void
  onClickDelete: (id: string) => void
}

export const DeleteConfirmModal: React.FC<Props> = (props) => {
  const {showModal, id, title, text, onClickCancel, onClickDelete} = props
  const modalContainerStyle = css({
    background: 'rgb(0, 0, 0, 0.6)',
    width: '100vw',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflowY: 'scroll'
  })
  const cardStyle = css({
    width: 300,
    maxWidth: 300,
    background: BACKGROUND_COLOR.PRIMARY,
    borderRadius: 10,
    padding: '30px 20px',
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    gap: 25,
    marginTop: 20,
    height: 'fit-content'
  })
  const titleStyle = css({
    textAlign: 'center',
    fontWeight: 'bold'
  })
  const textStyle = css({
    textAlign: 'center'
  })
  const buttonsStyle = css({
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignSelf: 'center',
    gap: 20
  })
  const buttonStyle = (type: 'cancel' | 'delete') => css({
    background: BACKGROUND_COLOR.TRANSPARENT,
    color: type === 'cancel' ? FONT_COLOR.SECONDARY : FONT_COLOR.ACCENT,
    border: 'none',
    borderRadius: 10,
    fontWeight: type === 'cancel' ? '' : 'bold',
    fontSize: 16,
    cursor: 'pointer'
  })

  if (!showModal) return null

  return (
    <ModalPortal>
      <div css={modalContainerStyle}>
        <div css={cardStyle}>
        <span css={titleStyle}>{title}</span>
          <span css={textStyle}>{text}</span>
          <span css={buttonsStyle}>
            <button css={buttonStyle('cancel')} onClick={onClickCancel}>キャンセル</button>
            <button css={buttonStyle('delete')} onClick={() => onClickDelete(id || '')}>削除する</button>
          </span>
        </div>
      </div>
    </ModalPortal>
  )
}