
import { css } from "@emotion/react";
import React from "react";
import { UserIcon } from "../atoms/UserIcon";

type Props = {
    icon: string,
    name: string,
    fontSize?: number
}

export const UserName: React.FC<Props> = ({icon, name, fontSize = 14}) => {
    const containerStyle = css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5,
        fontSize: fontSize,
        fontWeight: 800
    })

    return (
        <span css={containerStyle}>
            <UserIcon icon={icon} />
            <span>{name}さん</span>
        </span>
    )
}