import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetContent } from "../../hooks/useGetContent";
import { Loading } from "../atoms/Loading";
import { PostTemplate } from "../templates/Post";

export const Post: React.FC = () => {
  let { id } = useParams();
  const [postId, setPostId] = useState<string>('')
  const {data: content, error, isLoading} = useGetContent({id: postId})

  useEffect(() => {
    setPostId(id as string)
  }, [id])

  useEffect(() => {
    if (error?.status === 404) {
      throw Error('Content ID Not Found')
    }
  }, [error])

  if (isLoading) {
    return (
      <Loading />
    )
  }
  
  return (
    <PostTemplate content={content} />
  );
}