
import { css } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";
import { CloseIcon } from "../svg/CloseIcon";
import { CameraIcon } from "../svg/CameraIcon";
import { BACKGROUND_COLOR, BORDER_COLOR } from "../../resources/color";

export type Props = {
	onUpdateImages: (images: File[]) => void
  defaultImage?: string
};

export const ImageUploader: React.FC<Props> = ({onUpdateImages, defaultImage}) => {
	const [images, setImages] = useState<File[]>([]);
  const [uploadedImage, setUploadedImage] = useState<string>('')
  const [isEnabled, setIsEnabled] = useState<boolean>(true)
  const inputRef = useRef<HTMLInputElement>(null)
	const inputId = Math.random().toString(32).substring(2);
  const BLOCK_SIZE = 175
  const MAX_UPLOAD_COUNT = 1;
  
  useEffect(() => {
    if (defaultImage) {
      setUploadedImage(defaultImage)
      setIsEnabled(false)
    }
  }, [defaultImage])

	const handleOnAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) return;
    const newImages = [...images, ...e.target.files]
		setImages(newImages);
    onUpdateImages(newImages)
    setIsEnabled(newImages.length < MAX_UPLOAD_COUNT)
    if (!inputRef.current) return
    inputRef.current.value = '' // デフォルトだと同じファイルを選択してもonChangeが発火しないので、発火するようvalueを空にする
	};

	const handleOnRemoveImage = (index: number) => {
    if (uploadedImage) {
      setUploadedImage('')
      onUpdateImages([])
      setIsEnabled(true)
      return
    }
		const newImages = [...images];
		newImages.splice(index, 1);
		setImages(newImages);
    onUpdateImages(newImages)
    setIsEnabled(newImages.length < MAX_UPLOAD_COUNT)
	};

  const containerStyle = css({
    display: 'flex',
    flexDirection: 'row',
    gap: 30,
    width: '100%',
    flexWrap: 'wrap'
  })
  const cameraButtonStyle = css({
    width: BLOCK_SIZE,
    height: BLOCK_SIZE,
    background: isEnabled ? BACKGROUND_COLOR.ACCENT : BACKGROUND_COLOR.SECONDARY,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    cursor: 'pointer'
  })
  const imageContainerStyle = css({
    position: "relative",
    border: `1px solid ${BORDER_COLOR.PRIMARY}`,
    width: BLOCK_SIZE,
    height: BLOCK_SIZE,
  })
  const imageStyle = css({
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  })
  const closeButtonStyle = css({
    width: 16,
    position: 'absolute',
    top: 5,
    left: 5,
    cursor: 'pointer'
  })

	return (
    <div css={containerStyle}>
      <label htmlFor={inputId} css={cameraButtonStyle}>
        <CameraIcon />
        <input
          id={inputId}
          ref={inputRef}
          type="file"
          multiple
          accept="image/*,.png,.jpg,.jpeg,.gif"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleOnAddImage(e)
          }
          style={{ display: "none" }}
          disabled={!isEnabled}
        />
      </label>
      {(uploadedImage || images.length > 0) && <div
      css={imageContainerStyle}
      >
        <div css={closeButtonStyle} onClick={() => handleOnRemoveImage(0)}>
          <CloseIcon size={28} />
        </div>
        <img
          alt={'uploaded_image'}
          src={uploadedImage || URL.createObjectURL(images[0])}
          css={imageStyle}
        />
      </div>}
    </div>
	);
};