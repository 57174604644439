import axios from "axios";
import {
  convertObjectCamelToSnake,
  convertObjectSnakeToCamel,
} from "../utils/text";

type Props = {
  googleId?: string;
};

const postUser = (props: Props) => {
  // const headers = {
  //   'x-api-key': process.env.REACT_APP_API_KEY,
  // }
  return axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT}/user`,
      convertObjectCamelToSnake(props)
      // { headers }
    )
    .then((res) => {
      return convertObjectSnakeToCamel(res.data);
    });
};

export const usePostUser = () => {
  return { postUser: (props: Props) => postUser(props) };
};
