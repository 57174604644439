import React, { useEffect } from "react";

import { css } from "@emotion/react"
import { ThumbnailList } from "../organisms/ThumbnailList";
import { Post } from "../../types/post";

type Props = {
  contents: Post[]
  deleteContent?: (id: string) => void
}

export const ContentListTemplate: React.FC<Props> = (props) => {
  const {contents, deleteContent} = props

  useEffect(() => {
    document.title = 'トップ - うちのとりまわりじまん';
  });

  return (
    <div css={containerStyle}>
      <ThumbnailList contents={contents} deleteContent={deleteContent && deleteContent} />
    </div>
  );
}
const containerStyle = css({
  width: '100vw',
  
})
