import React, { useCallback, useEffect, useState } from "react";
import firebase from 'firebase/compat/app';
import { css } from "@emotion/react"
import { BACKGROUND_COLOR, FONT_COLOR } from "../../resources/color";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userState } from "../../store/userState";
import { usePutUser } from "../../hooks/usePutUser";
import { ActionButton } from "../atoms/ActionButton";
import useDeviceType from "../../hooks/useDeviceType";
import { DeleteConfirmModal } from "../organisms/DeleteConfirmModal";
import { useDeleteUser } from "../../hooks/useDeleteUser";
import { LoadingOverlay } from "../atoms/LoadingOverlay";

export const SettingsTemplate: React.FC = () => {
  const navigate = useNavigate()
  const [user, setUser] = useRecoilState(userState)
  const [isFilled, setIsFilled] = useState<boolean>(user?.userName.length > 0)
  const [name, setName] = useState<string>(user?.userName)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {putUser} = usePutUser()
  const {deleteUser} = useDeleteUser()
  const deviceType = useDeviceType()
  const isMobile = deviceType === 'mobile'

  useEffect(() => {
    // リソース化したい
    document.title = '設定 - うちのとりまわりじまん';
  });

  const onChangeName = useCallback((e: any) => {
    setIsFilled(e.target.value.length > 0)
    setName(e.target.value)
  }, [])
  const onClickEditButton = useCallback(async () => {
    setIsLoading(true)
    const userInfo = {userId: user?.userId, userName: name}
    await putUser({userId: user?.userId, userName: name})
    setUser(userInfo)
    setIsLoading(false)
    navigate('/')
  }, [navigate, name, putUser, user?.userId, setUser])
  const onClickDeleteButton = useCallback(
    () => {
      setShowModal(true)
    },
    [setShowModal],
  )
  const onClickCancelDelete = useCallback(
    () => {
      setShowModal(false)
    },
    [setShowModal],
  )
  const onClickExecuteDelete = useCallback(
    async () => {
      setIsLoading(true)
      await deleteUser({userId: user?.userId})
      firebase.auth().signOut().then(() => {
        setIsLoading(false)
        navigate('/')
        setUser(null)
      }).catch((e) => {
        setIsLoading(false)
        console.log('error: ', e)
      })
    },
    [deleteUser, navigate, setUser, user?.userId],
  )

  const containerStyle = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100vw',
    gap: 40,
    marginTop: isMobile ? 110 : 160,
  })
  const contentsStyle = css({
    display: 'flex',
    flexDirection: 'column',
    width: isMobile ? 'calc(100vw - 40px)' : 400,
    gap: isMobile ? 14 : 18
  })
  const titleStyle = css({
    fontWeight: 700,
    color: FONT_COLOR.PRIMARY,
    fontSize: 18
  })
  const contentWrapperStyle = css({
    display: 'flex',
    flexDirection: 'column',
    gap: 10
  })

  return (
    <>
      <div css={containerStyle}>
        <span css={contentsStyle}>
          <span css={titleStyle}>ユーザー名変更</span>
          <span css={contentWrapperStyle}>
            <span>投稿時に表示される名前です。</span>
            <input value={name} css={inputNameStyle} type='text' placeholder="1文字以上入力してください" onChange={(e) => onChangeName(e)} />
            <ActionButton
              text="変更する"
              onClick={onClickEditButton}
              disabled={!isFilled}
              size='large'
            />
          </span>
        </span>
        <span css={contentsStyle}>
          <span css={titleStyle}>アカウント削除</span>
          <span css={contentWrapperStyle}>
            <span>アカウント情報と、投稿したすべてのコンテンツが削除されます。一度実行すると、元に戻すことができません。</span>
            <ActionButton
              text="削除する"
              onClick={onClickDeleteButton}
              size='large'
              backgroundColor={BACKGROUND_COLOR.ATTENTION}
            />
          </span>
        </span>
      </div>
      <DeleteConfirmModal
        showModal={showModal}
        title='アカウント削除'
        text='元に戻すことはできません。アカウント削除を実行してよろしいですか？'
        onClickCancel={onClickCancelDelete}
        onClickDelete={onClickExecuteDelete}
      />
      {isLoading && <LoadingOverlay />}
    </>
  )
}

const inputNameStyle = css({
  padding: '0 10px',
  border: `1px solid ${FONT_COLOR.PRIMARY}`,
  outline: 'none',
  borderRadius: 5,
  fontSize: 16,
  width: 'calc(100% - 20px)',
  height: 46,
})