
import { css } from "@emotion/react";
import Close from '../../assets/close.png'
import React, { useCallback, useEffect, useState } from "react";
import { BACKGROUND_COLOR, FONT_COLOR } from "../../resources/color";
import { TagList } from "../molecules/TagList";
import { TAGS } from "../../resources/tags";
import { ImageUploader } from "../atoms/ImageUploader";
import { ModalPortal } from "../portal/ModalPortal";
import { useRecoilValue } from "recoil";
import { usePostContent } from "../../hooks/usePostContent";
import { userState } from "../../store/userState";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetContent } from "../../hooks/useGetContent";
import { usePutContent } from "../../hooks/usePutContent";

type Tag = {
  id: number
  category: string
  text: string
  selected: boolean
}

export type RequestContent = {
  userId: string
  id?: string
  title: string
  description: string
  tags: number[]
  imageBase64: string
}

type Props = {
  onClose: () => void,
  id?: string
}

export const PostModal: React.FC<Props> = ({onClose, id}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const user = useRecoilValue(userState)
  const {postContent} = usePostContent()
  const {putContent} = usePutContent()
  const {data: content, isLoading: isLoadingContent} = useGetContent({id})
  const [tags, setTags] = useState<Tag[]>([])
  const [isFilled, setIsFilled] = useState<boolean>(false)
  const [contents, setContents] = useState<RequestContent>({
    userId: user?.userId,
    title: '',
    description: '',
    tags: [],
    imageBase64: ''
  })
  const [isPosting, setIsPosting] = useState<boolean>(false)

  const initTags = useCallback(() => {
    if (!content?.tags || content?.tags.length === 0) {
      setTags(TAGS.map((t) => ({...t, selected: false})))
      return
    }
    setTags(TAGS.map((t) => {
      if (content?.tags.includes(t.id)) return {...t, selected: true}
      else return {...t, selected: false}
    }))
  }, [content])

  const initContents = useCallback(() => {
    setContents({
      userId: user?.userId,
      title: content?.title || '',
      description: content?.description || '',
      tags: content?.tags || [],
      imageBase64: content?.imageUrl || ''
    })
  }, [user?.userId, content])

  useEffect(() => {
    if (id && isLoadingContent) return
    initTags()
    initContents()
  }, [id, initTags, initContents, isLoadingContent, content])

  useEffect(() => {
    if (!user) return
    setContents((prev) => ({...prev, userId: user.userId}))
  }, [user])

  useEffect(() => {
    setIsFilled(contents.title.length > 0 && contents?.description.length > 0 && contents.imageBase64.length > 0)
  }, [contents.title, contents.description, contents.imageBase64, contents])

  const onChangeTitle = useCallback((e: any) => {
    setContents((prev) => ({...prev, title: e.target.value}))
  }, [])

  const onChangeDescription = useCallback((e: any) => {
    setContents((prev) => ({...prev, description: e.target.value}))
  }, [])

  const onChangeTags = useCallback((item: any) => {
    setTags(tags.map((tag) => tag.id === item.id ? item : tag))
  }, [tags])

  const setBase64Image = useCallback((image: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      setContents((prev) => ({...prev, imageBase64: (reader.result as string).split(',')[1]}))
    };
  }, [])

  const onUpdateImages = useCallback((images: File[]) => {
    if (images.length === 0) {
      setContents((prev) => ({...prev, imageBase64: ''}))
      return
    }
    setBase64Image(images[0])
  }, [setBase64Image])

  const closeModal = useCallback(() => {
    initTags()
    initContents()
    onClose()
  }, [initTags, initContents, onClose])

  const onClickPostButton = useCallback(async() => {
    setIsPosting(true)
    const selectedTagIds = tags.filter((t) => t.selected === true).map((t) => t.id)
    const requestContent = {...contents, tags: selectedTagIds}
    if (id) {
      let request: {
        userId: string,
        id: string,
        title?: string,
        description?: string,
        tags?: number[],
        imageBase64?: string
      } = {...requestContent, id}
      if (request.title === content.title) delete request.title
      if (request.description === content.description) delete request.description
      if (request.tags === content.tags) delete request.tags
      if (request.imageBase64 === content.imageUrl) delete request.imageBase64
      try {
        await putContent({content: request})
      } catch(error) {
        alert('エラーが発生しました。時間を置いて、再度お試しください。')
        setIsPosting(false)
      } finally {
        closeModal()
        setIsPosting(false)
        if (location.pathname === '/') window.location.reload()
        else navigate('/')
      }
      return
    }
    try {
      await postContent({content: requestContent})
    } catch(error) {
      alert('エラーが発生しました。時間を置いて、再度お試しください。')
      setIsPosting(false)
    } finally {
      closeModal()
      setIsPosting(false)
      if (location.pathname === '/') window.location.reload()
      else navigate('/')
    }
  }, [closeModal, tags, contents, postContent, location.pathname, navigate, setIsPosting, content, id, putContent])

  const modalContainerStyle = css({
    background: 'rgb(0, 0, 0, 0.6)',
    width: '100vw',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    overflowY: 'scroll'
  })
  const buttonStyle = css({
    width: '100%',
    backgroundColor: BACKGROUND_COLOR.ACCENT,
    border: 'none',
    height: 46,
    borderRadius: 5,
    color: FONT_COLOR.REVERSED,
    fontSize: 16,
    fontWeight: 800,
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: isFilled ? 'pointer' : ''
  },
  `
    &:disabled {
      background-color: ${BACKGROUND_COLOR.SECONDARY}
    }
  `
  )

  return (
    <ModalPortal>
      <div css={modalContainerStyle}>
        <div css={modalCardStyle}>
          <span css={modalHeaderStyle}>
            <img css={closeStyle} src={Close} alt="close" onClick={closeModal} />
            <span css={modalTitleStyle}>{id ? '編集' : '新規投稿'}</span>
          </span>
          <input css={inputTitleStyle} type='text' placeholder="タイトル" onChange={(e) => onChangeTitle(e)} value={contents.title} />
          <textarea css={inputDescriptionStyle} id="story" name="story" placeholder="利用シーンや思い出を書いてください。" onChange={(e) => onChangeDescription(e)} defaultValue={content?.description || ''} />
          <div css={tagFieldStyle}>
          <TagList items={tags} fontSize={14} onChangeTags={onChangeTags} />
          </div>
          <ImageUploader onUpdateImages={onUpdateImages} defaultImage={content?.imageUrl} />
          <button onClick={onClickPostButton} css={buttonStyle} disabled={!isFilled || isPosting}>投稿</button>
        </div>
      </div>
    </ModalPortal>
  )
}
const modalCardStyle = css({
  width: 600,
  maxWidth: 600,
  background: BACKGROUND_COLOR.PRIMARY,
  borderRadius: 10,
  padding: '40px 50px',
  display: "flex",
  flexDirection: 'column',
  justifyContent: "center",
  gap: 25,
  marginTop: 20,
  height: 'fit-content'
})
const modalHeaderStyle = css({
  textAlign: "center",
  position: 'relative'
})
const modalTitleStyle = css({
  fontWeight: 800,
  fontSize: 18
})
const inputTitleStyle = css({
  padding: 10,
  border: `1px solid ${FONT_COLOR.PRIMARY}`,
  outline: 'none',
  borderRadius: 5,
  fontSize: 16,
  width: 'calc(100% - 20px)',
})
// const tagRowStyle = css({
//   display: 'flex',
//   flexDirection: 'row',
//   gap: 15,
//   alignItems: 'top',
// })
// const tagRowCategoryStyle = css({
//   fontWeight: 800,
//   fontSize: 16,
//   color: FONT_COLOR.PRIMARY,
//   whiteSpace: 'nowrap'
// })
const inputDescriptionStyle = css({
  padding: 10,
  border: `1px solid ${FONT_COLOR.PRIMARY}`,
  borderRadius: 5,
  outline: 'none',
  fontSize: 16,
  fontFamily: 'Hiragino Kaku Gothic Pro', // textareaだけ違うフォントになるのでこれで一旦回避している
  width: 'calc(100% - 20px)',
  margin: 'auto',
  height: 200
})
const tagFieldStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 15
})
const closeStyle = css({
  width: 20,
  position: 'absolute',
  top: 0,
  right: 0,
  cursor: 'pointer'
})