import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios'
import { convertObjectSnakeToCamel } from '../utils/text';

type Props = {
  userId?: string
}

export const useGetContents = (props: Props = {}) => {
  const {userId} = props
  const [response, setResponse] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const partialPath = userId ? `/${userId}` : ''
    // const headers = {
    //   'x-api-key': process.env.REACT_APP_API_KEY,
    // }
    axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/torimawaris${partialPath}`,
      // { headers }
    ).then(res => {
      const dataForLocal = res.data.map((d: any) => {
        return {...d, image_url: d.image_url.replace('http://minio', 'http://localhost')}
      }) // FIXME: ローカルテストよう
      const formattedResponse = dataForLocal.map((d: any) => convertObjectSnakeToCamel(d)) // FIXME: ローカルテストよう
      // const formattedResponse = res.data.map((d: any) => convertObjectSnakeToCamel(d))
      setResponse(formattedResponse)
    }).finally(() => {
      setIsLoading(false)
    })
  }, [userId])

  return {data: response, isLoading}
}