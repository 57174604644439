
import { css } from "@emotion/react"
import { FONT_COLOR } from "../../resources/color"

export const Loading: React.FC = () => {
    return (
      <div css={containerStyle}>
        読み込み中...
      </div>
    )
}

const containerStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
  color: FONT_COLOR.SECONDARY,
  fontSize: 20,
  fontWeight: 'bold'
})