import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoginTemplate } from "../templates/Login";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { GoogleAuthProvider } from "@firebase/auth";
import { useSetRecoilState } from "recoil";
import { userState } from "../../store/userState";
import { usePostUser } from "../../hooks/usePostUser";

export const Login: React.FC = () => {
  const navigate = useNavigate()
  const setUser = useSetRecoilState(userState);
  const {postUser} = usePostUser()

  const signInWithGoogle = useCallback(() => {
    firebase.auth().signInWithPopup(new GoogleAuthProvider())
    // firebase.auth().getRedirectResult()
    .then(async (result) => {
      if (!result || !result.credential || !result.user) return
      // if (!result?.user?.uid) throw Error()
      const user = await postUser({googleId: result?.user?.uid})
      setUser({userId: user.userId, userName: user.userName})
      navigate('/')
    }).catch((error) => {
      alert(error.message);
    });
    // const provider = new GoogleAuthProvider();
    // firebase.auth().signInWithRedirect(provider)
  }, [navigate, postUser, setUser])

  // useEffect(() => {
  //   firebase.auth().signInWithPopup(new GoogleAuthProvider())
  //   // firebase.auth().getRedirectResult()
  //   .then(async (result) => {
  //     if (!result || !result.credential || !result.user) return
  //     // if (!result?.user?.uid) throw Error()
  //     const user = await postUser({googleId: result?.user?.uid})
  //     setUser({userId: user.userId, userName: user.userName})
  //     navigate('/')
  //   }).catch((error) => {
  //     alert(error.message);
  //   });
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  
  // Pop-upの認証版
  // const signInWithGoogle = useCallback(() => {
  //   const provider = new GoogleAuthProvider();
  //   firebase.auth().signInWithRedirect(provider)
  //   .then(async (result) => {
  //     if (!result?.user?.uid) throw Error()
  //     const user = await postUser({userId: result?.user?.uid})
  //     setUser({userId: user.userId, userName: user.userName})
  //     navigate('/')
  //   })
  //   .catch(error => {
  //     if (error.code === 'auth/cancelled-popup-request') return
  //     if (error.code === 'auth/popup-closed-by-user') return
  //     alert(error.message);
  //   });
  // }, [navigate, setUser, postUser])

  const onClickLogin = useCallback(
    () => {
      signInWithGoogle()
    },
    [signInWithGoogle],
  )
  
  return (
    <LoginTemplate onClickLogin={onClickLogin} />
  )
}