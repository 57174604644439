import { useLayoutEffect, useState } from "react";

const useDeviceType = () => {
  const [type, setType] = useState<'mobile' | 'tablet' | 'desktop'>('desktop')
  useLayoutEffect(() => {
    const updateSize = (): void => {
      const w = window.innerWidth
      if (w < 768) setType('mobile')
      else if (w < 1281) setType('tablet')
      else setType('desktop')
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return type;
};

export default useDeviceType;