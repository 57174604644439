import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: 'recoil-persist',
  storage: localStorage,
});

// 型をUserにするとLogin.tsxで型エラーになる
export const userState = atom<any>({
  key: "userState",
  default: null,
  effects_UNSTABLE: [persistAtom]
});