import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useDeleteContent } from "../../hooks/useDeleteContent";
import { useGetContents } from "../../hooks/useGetContents";
import { userState } from "../../store/userState";
import { Empty } from "../atoms/Empty";
import { Loading } from "../atoms/Loading";
import { ContentListTemplate } from "../templates/ContentList";

type ContentResponse = {
  createdAt: string
  description: string
  id: string
  imageUrl: string
  tags: number[]
  title: string
  userId: string
  userName: string
}[]

export const MyPosts: React.FC = () => {
  const user = useRecoilValue(userState)
  const [contents, setContents] = useState<ContentResponse>([])
  const {data: contentsData, isLoading} = useGetContents({userId: user?.userId})
  const {deleteContent} = useDeleteContent()

  useEffect(() => {
    document.title = 'じぶんの投稿 - うちのとりまわりじまん';
  });

  useEffect(() => {
    // ログインされていなかったらエラーページを表示する
    if (!user) throw Error()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setContents(contentsData)
  }, [contentsData])

  const onDeleteContent = useCallback(async (id: string) => {
    try {
      await deleteContent({userId: user?.userId, id})
    } catch(error) {
      alert('エラーが発生しました。時間を置いて、再度お試しください。')
    } finally {
      window.location.reload()
    }
  }, [deleteContent, user])

  if (isLoading) {
    return (
      <Loading />
    )
  }

  if (!contents || contents.length === 0) {
    return (
      <Empty />
    )
  }

  return (
    <ContentListTemplate contents={contents} deleteContent={onDeleteContent} />
  );
}