import axios from 'axios'
import { convertObjectCamelToSnake, convertObjectSnakeToCamel } from '../utils/text';

type Props = {
  userId: string
  userName: string
}

const putUser = (props: Props) => {
  // const headers = {
  //   'x-api-key': process.env.REACT_APP_API_KEY,
  // }
  return axios.put(
    `${process.env.REACT_APP_API_ENDPOINT}/user`,
    convertObjectCamelToSnake(props),
    // { headers }
  ).then(res => {
    return convertObjectSnakeToCamel(res.data)
  })
}

export const usePutUser = () => {
  return {putUser: (props: Props) => putUser(props)}
}