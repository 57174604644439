
import { css } from "@emotion/react";
import React from "react";

type Props = {
    icon: string
    size?: number
}

export const UserIcon: React.FC<Props> = ({icon, size=20}) => {
    // TODO: 画像を受け入れるようにする
    const userIconStyle = css({
        borderRadius: '50%',
        width: size,
        height: size,
        background: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 2px 2px 0'
    })
    return (
        <div css={userIconStyle}>{icon}</div>
    )
}