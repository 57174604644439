
import { css } from "@emotion/react"
import { BACKGROUND_COLOR, FONT_COLOR } from "../../resources/color"
import { ModalPortal } from "../portal/ModalPortal"

export const LoadingOverlay: React.FC = () => {
    return (
      <ModalPortal>
        <div css={containerStyle}>
          処理しています...
        </div>
      </ModalPortal>
    )
}

const containerStyle = css({
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
  background: BACKGROUND_COLOR.OVERLAY,
  color: FONT_COLOR.REVERSED,
  fontSize: 20,
  fontWeight: 'bold',
})