
import React from "react";
import { useGetContents } from "../../hooks/useGetContents";
import { Loading } from "../atoms/Loading";
import { ContentListTemplate } from "../templates/ContentList";

export const Top: React.FC = () => {
  const {data: contents, isLoading} = useGetContents()

  if (isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <ContentListTemplate contents={contents} />
  );
}