export const CATEGORIES = [
  {
    value: 'birdType',
    text: '鳥種'
  },
  {
    value: 'birdSize',
    text: '鳥サイズ'
  },
  {
    value: 'other',
    text: 'その他'
  }
]

export const TAGS = [
  {
    id: 0,
    category: 'birdSize',
    text: '小型',
  },
  {
    id: 1,
    category: 'birdSize',
    text: '中型',
  },
  {
    id: 2,
    category: 'birdSize',
    text: '大型',
  },
  {
    id: 100,
    category: 'birdType',
    text: '文鳥',
  },
  {
    id: 101,
    category: 'birdType',
    text: 'キンカチョウ',
  },
  {
    id: 102,
    category: 'birdType',
    text: 'ジュウシマツ',
  },
  {
    id: 103,
    category: 'birdType',
    text: 'フィンチ',
  },
  {
    id: 104,
    category: 'birdType',
    text: 'セキセイインコ',
  },
  {
    id: 105,
    category: 'birdType',
    text: 'コザクラインコ',
  },
  {
    id: 106,
    category: 'birdType',
    text: 'オカメインコ',
  },
  {
    id: 107,
    category: 'birdType',
    text: 'マメルリハ',
  },
  {
    id: 108,
    category: 'birdType',
    text: 'ウロコインコ',
  },
  {
    id: 109,
    category: 'birdType',
    text: 'コガネメキシコインコ',
  },
  {
    id: 110,
    category: 'birdType',
    text: 'モモイロインコ',
  },
  {
    id: 111,
    category: 'birdType',
    text: 'ボタンインコ',
  },
  {
    id: 112,
    category: 'birdType',
    text: 'サザナミインコ',
  },
  {
    id: 113,
    category: 'birdType',
    text: 'オキナインコ',
  },
  {
    id: 114,
    category: 'birdType',
    text: 'インコ',
  },
  {
    id: 115,
    category: 'birdType',
    text: 'タイハクオウム',
  },
  {
    id: 116,
    category: 'birdType',
    text: 'キバタン',
  },
  {
    id: 117,
    category: 'birdType',
    text: 'オウム',
  },
  {
    id: 118,
    category: 'birdType',
    text: 'フクロウ',
  },
  {
    id: 119,
    category: 'birdType',
    text: '猛禽類',
  },
  {
    id: 200,
    category: 'other',
    text: 'DIY',
  }
]