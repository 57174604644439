export const convertTextSnakeToCamel = (str: any) => {
  return str.split('_').map(function(word: any, index: number){
    if (index === 0) {
      return word.toLowerCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join('');
}

export const convertObjectSnakeToCamel = (obj: any) => {
  const result: {[prop: string]: any} = {}
  Object.keys(obj).forEach((key) => {
    result[convertTextSnakeToCamel(key)] = obj[key]
  })
  return result
}

export const convertTextCamelToSnake = (str: any) => {
  return str.split(/(?=[A-Z])/).join('_').toLowerCase()
}

export const convertObjectCamelToSnake = (obj: any) => {
  const result: {[prop: string]: any} = {}
  Object.keys(obj).forEach(key => {
    result[convertTextCamelToSnake(key)] = obj[key]
  })
  return result
}