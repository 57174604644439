import { css } from "@emotion/react";
import { ThumbnailCard } from "../molecules/ThumbnailCard";
import React, { useCallback, useState } from "react";
import { TAGS } from "../../resources/tags";
import { Post } from "../../types/post";
import { DeleteConfirmModal } from "./DeleteConfirmModal";
import useDeviceType from "../../hooks/useDeviceType";
import PORY from "../../assets/pory_house.jpg"

type Props = {
  contents: Post[]
  deleteContent?: (id: string) => void
}

export const ThumbnailList: React.FC<Props> = (props) => {
  const {contents, deleteContent} = props
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [selectedContentId, setSelectedContentId] = useState<string>('')
  const deviceType = useDeviceType()

  const onClickDeleteButton = useCallback((id: string) => {
    setShowConfirmModal(true)
    setSelectedContentId(id)
  }, [])
  const onClickCancel = useCallback(() => {
    setShowConfirmModal(false)
    setSelectedContentId('')
  }, [])
  const onClickDelete = useCallback((id: string) => {
    // 削除API呼ぶ
    deleteContent && deleteContent(id)
    setShowConfirmModal(false)
    setSelectedContentId('')
  }, [deleteContent])

  const contentStyle = css({
    padding: deviceType === 'mobile' ? `110px 20px 30px` : `110px 30px 30px`,
  })

  return (
    <div css={contentStyle}>
      {/* <div css={filterContainerStyle}>
        <div css={filterItemStyle}>
            <span>文鳥</span>
            <span css={triangleStyle}></span>
        </div>
        <div css={filterItemStyle}>
          <span>新着順</span>
          <span css={triangleStyle}></span>
        </div>
      </div> */}
      <div css={cardContainerStyle}>
        {/* <ThumbnailCard 
          key={`top-thumbnail-aaa`}
          userId={'hello'}
          id={'world'}
          title={'toridesu'}
          imageUrl={PORY}
          name={'toridesuyo'}
          tags={[1, 105, 114]}
          likes={0}
          onClickDeleteButton={onClickDeleteButton}
        /> */}
        {contents?.sort((a, b) => {
          return a.createdAt > b.createdAt ? -1 : 1
        }).map((v, i) => (
          <ThumbnailCard 
            key={`top-thumbnail-${v.id}`}
            userId={v.userId}
            id={v.id}
            title={v.title}
            imageUrl={v.imageUrl}
            name={v.userName}
            tags={TAGS.filter((t) => v.tags?.includes(t.id))}
            likes={0}
            onClickDeleteButton={onClickDeleteButton}
          />
        ))}
      </div>
      <DeleteConfirmModal
        showModal={showConfirmModal}
        id={selectedContentId}
        title={contents?.filter((c) => c.id === selectedContentId)[0]?.title}
        text='このタイトルの投稿を削除しますか？'
        onClickCancel={onClickCancel}
        onClickDelete={onClickDelete}
      />
    </div>
  )
}

// const filterContainerStyle = css({
//   display: "flex",
//   flexDirection: "row",
//   gap: 30,
//   marginBottom: 15,
// })
// const filterItemStyle = css({
//   display: "flex",
//   flexDirection: "row",
//   alignItems: "center",
//   gap: 10
// })
// const triangleStyle = css({
//   borderTop: '6px solid gray',
//   borderRight: '6px solid transparent',
//   borderLeft: '6px solid transparent',
// })
const cardContainerStyle = css({
  display: "flex",
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 20
})
